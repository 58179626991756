<template>
  <div class="commission-profiles-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="8" :md="8" :sm="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            :icon="['fas', 'users-gear']"
            class="anticon"
            :style="{ marginRight: '10px' }"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="16" :md="16" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[0, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
          class="row-reverse"
        >
          <a-col :xs="24" :md="13" :lg="15">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col :xs="24" :md="5" :lg="4" :style="{ textAlign: 'right' }">
            <router-link
              v-if="canCreate"
              :to="{ name: 'admin.commission-profiles.create' }"
            >
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchCommissionProfiles"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          :scroll="{ x: true }"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters } from "vuex";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";
export default {
  components: { StandardTable },
  name: "CommissionProfileList",
  metaInfo: {
    title: adminPageTitle("Perfiles de comisión"),
  },
  data() {
    return {
      labels: labels.commissionProfiles,
      search: "",
    };
  },
  methods: {
    ...mapActions("commissionProfiles", [
      "fetchCommissionProfiles",
      "deleteCommissionProfile",
    ]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteCommissionProfile(id);
            this.$message.success(this.labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.deletedError
            );
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    canCreate() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_USER_COMMISSION_PROFILES_CREATE,
      ]);
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_USER_COMMISSION_PROFILES_DELETE,
      ]);
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.id,
          dataIndex: "id",
          key: "id",
          sorter: true,
          width: "180px",
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.name,
          dataIndex: "name",
          key: "name",
          sorter: true,
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.profile,
          dataIndex: "profile",
          key: "profile",
          sorter: true,
          width: "220px",
          customRender: (text) => {
            return `${text?.name || "-"}`;
          },
        },
        {
          title: this.labels.table.columns.benefits,
          dataIndex: "schema",
          key: "schema",
          width: "180px",
          align: "center",
          sorter: false,
          customRender: (text) => {
            return (
              <a-badge
                count={text?.length || 0}
                number-style={{ backgroundColor: "#52c41a" }}
              />
            );
          },
        },
        {
          title: this.labels.table.columns.createdAt,
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          width: "220px",
          customRender: (text) => {
            return this.$moment(text).format(this.tableDateFormat);
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          width: "180px",
          align: "center",
          sorter: true,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.status.active
                  : this.labels.table.columns.status.inactive}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.commission-profiles.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="close-circle"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
      ];
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    tableDateFormat() {
      return "DD/MM/YYYY hh:mm:ss A";
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.commission-profiles-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  @media screen and (max-width: $screen-mobile) {
    .row-reverse {
      flex-direction: column-reverse;
    }
  }
}
</style>
